/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-18 09:47:04
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-12 14:09:03
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listCustomerInfo = params => axios({
    url: '/api/dealer/dealer/dealerInfo/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addCustomerInfo = params => axios({
    url: '/api/dealer/dealer/dealerInfo/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editCustomerInfo = params => axios({
    url: '/api/dealer/dealer/dealerInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delCustomerInfo = params => axios({
    url:'/api/dealer/dealer/dealerInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdCustomerInfo = params => axios({
    url: '/api/dealer/dealer/dealerInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
